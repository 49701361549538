import { Table } from 'antd';
import React, { useMemo } from 'react';
import { useFetchExecutionDetailsList } from 'api/executions/executions.api';
import { ColumnsType } from 'antd/lib/table/Table';
import { IExecutionDetail } from 'models/api/signature.model';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const ExecutionDetailListPage = () => {
  const { executionId } = useParams<any>();
  const { data, loading } = useFetchExecutionDetailsList(executionId);

  const columns = useMemo(
    (): ColumnsType<IExecutionDetail> => [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'EXECUTED BY',
        dataIndex: 'execution_by',
      },
      {
        title: 'STATUS',
        dataIndex: 'success',
        render(_, record) {
          if (!record.processed) return 'PENDING';
          if (!record.success) return 'FAILED';
          return 'SUCCESS';
        },
      },
      {
        title: 'START TIME',
        dataIndex: 'execution_start',
        render: (value: number) => moment(value).format('LLL'),
      },
      {
        title: 'END TIME',
        dataIndex: 'execution_end',
        render: (value: number) => (value ? moment(value).format('LLL') : null),
      },
    ],
    [data],
  );

  return (
    <div style={{ overflowX: 'auto' }} className="beauty-scroll">
      <Table
        className="mt-1"
        loading={loading}
        rowKey={(record) => record.id}
        dataSource={data.details}
        columns={columns}
        pagination={undefined}
      />
    </div>
  );
};

export default ExecutionDetailListPage;
