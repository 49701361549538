import { ITypeAuthAction } from 'models/global.model';
import { type } from '../types/index.type';

const init = {
  loading: true,
  authenticated: false,
  displayName: '',
  image: '',
  google_id: '',
  email: '',
  google: {
    access_token: '',
    plan: 'Trial',
    currentMembership: 'annually',
  },
  admin_email: '',
  admin_full_name: '',
  admin_id: '',
  admin_refresh_token: '',
  billing_active: 0,
  can_create: 1,
  count_app: 0,
  count_ou: 0,
  country_code: '',
  country_name: '',
  created_at: null,
  customer_id: '',
  edition: '',
  id: null,
  job_title: '',
  licenses: 0,
  number_of_employees: '',
  payment_method: '',
  phone: '',
  purchase_date: null,
  quota_exceeded: 0,
  refresh_token: '',
  summary: '',
  sync: 1,
  text_msg_code: 1,
  token: '',
  type_gaps: '',
  uninstalled: null,
  user_email: '',
  user_id: '',
};

const AuthReducer = (state: typeof init = init, action: ITypeAuthAction) => {
  switch (action.type) {
    case type.authSet:
      return { ...state, ...action.payload, loading: false };
    case type.authLogin:
      return { ...state, ...action.payload };
    case type.authUpdatePlan:
      return {
        ...state,
        google: {
          ...state.google,
          plan: action.payload.plan,
          currentMembership: action.payload.currentMembership,
        },
        edition: action.payload.edition,
      };

    case type.authReset:
      return init;
    default:
      return state;
  }
};

export default AuthReducer;
