export default function getCurrentPayment(plan: string, current: string) {
  const currentTime = current === 'annually' ? 'yearly' : current;
  return `${plan.toLowerCase()}_${currentTime}`;
}

export function transformPlanToEdition(
  plan: string,
  current: string,
): string | null {
  if (plan === 'Pro' && current === 'monthly') return 'ZPRO1M';
  if (plan === 'Pro' && current === 'annually') return 'ZPRO12M';
  if (plan === 'Enterprise' && current === 'monthly') return 'ZENT1M';
  if (plan === 'Enterprise' && current === 'annually') return 'ZENT12M';
  return null;
}
