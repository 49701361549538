import { Typography } from 'antd';
import Plans from 'pages/settings/components/plans';
import { dataPricingSimplify } from 'utils/utils';

const UpgradeSub = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%',
        padding: '16px',
        boxSizing: 'border-box',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '1200px',
          width: '100%',
          margin: '0 auto',
          padding: '16px',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ width: '100%' }}>
          <div>
            <Typography.Title level={2}>Upgrade Plan</Typography.Title>
          </div>
          <Typography.Paragraph>
            Selecciona el plan que mejor se adapte a tus necesidades y procede
            con la actualización.
          </Typography.Paragraph>
          <Plans data={dataPricingSimplify} />
        </div>
      </div>
    </div>
  );
};

export default UpgradeSub;
