import { type } from '../types/index.type';

export function resetAuth() {
  return (dispatch: any) => {
    dispatch({ type: type.authReset });
  };
}

export function setAuth(payload: any) {
  return (dispatch: any, getState: any) => {
    const { auth } = getState();
    dispatch({ type: type.authSet, payload: { ...auth, ...payload } });
  };
}

export function setGoogleAuthenticate(payload: any) {
  return (dispatch: any, getState: any) => {
    const { auth } = getState();
    const newPayload = { ...auth, google: { ...auth.google, ...payload } };
    localStorage.setItem('auth', JSON.stringify(newPayload));
    dispatch({ type: type.authSet, payload: newPayload });
  };
}

export function setAuthenticate(beforePayload: any) {
  return (dispatch: any, getState: any) => {
    const { auth } = getState();
    const payload = !!beforePayload.google
      ? {
          ...auth,
          ...beforePayload,
          google: { ...auth.google, ...beforePayload.google },
        }
      : beforePayload;
    localStorage.setItem('auth', JSON.stringify(payload));
    dispatch({ type: type.authLogin, payload });
  };
}

export function updatePlan(
  plan: string,
  currentMembership: string,
  edition: string,
) {
  return (dispatch: any, getState: any) => {
    const { auth } = getState();
    const newPayload = {
      ...auth,
      google: {
        ...auth.google,
        plan,
        currentMembership,
      },
      edition,
    };
    localStorage.setItem('auth', JSON.stringify(newPayload));
    dispatch({ type: type.authSet, payload: newPayload });
  };
}
